import { Link } from "@StarberryUtils";
import React, {useEffect, useState } from "react"
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useLocation } from "@reach/router";
import HTMLReactParser from 'html-react-parser';
import $ from 'jquery';

// images
import ImgPerson from "../../../images/person.jpg"
import cardImg01 from "../../../images/home/card-img01.webp"
import cardImg02 from "../../../images/home/card-img02.webp"
import cardImg03 from "../../../images/home/card-img03.webp"
import cardImg04 from "../../../images/home/card-img04.webp"
import cardImg05 from "../../../images/home/card-img05.webp"
import cardImg06 from "../../../images/home/card-img06.webp"
import cardImg07 from "../../../images/home/card-img07.webp"
import cardImg08 from "../../../images/home/card-img08.webp"
import ContactCard from "../ContactCard/ContactCard"
// Style
import './ContactInfo.scss';
import ScrollAnimation from 'react-animate-on-scroll';

import { ImageModule } from "../../../modules/Image_Module";
import AccordionBlock from "../../AccordionBlock/AccordionBlock"
import Accordion from "react-bootstrap/Accordion"
import { SubCommunityDetails, PropertiesCount } from "../../../queries/common_use_query";
import {CommunityDetails, CommunityDistrict} from "../../../queries/common_use_query";
import logo from '../../../images/favicon.png'
import { isbot } from "isbot";
import { removeDataAttributes } from "../../../comQueryStructure";

const ContactInfo = (props) => {
const offficeData = removeDataAttributes(props?.Office_Details)

    // console.log("Services_List", props.Accordian_List);

    const [activeId, setActiveId] = useState(props.activeId ? props.activeId : "0");
    const [viewMore, setViewMore] = useState(props.viewMore);
    const [details, setDetails] = useState('');
    const [readMore, setReadMore] = useState(props.Content.length > 650 ? true : false);
    const [showFullContent, setShowFullContent] = useState(false);
    const [userAgent, setUserAgent] = useState(null);

    const toggleDescContent = () => {
        setShowFullContent(!showFullContent);
        toggleReadMore();
    };

    const toggleReadMore = () => {
        setReadMore(!readMore);
      };

    function toggleActive(id) {
        if (activeId === id) {
            setActiveId(null)
        } else {
            setActiveId(id)
        }
    }
    const {loading:pro_loading, error:pro_error, data:pro_data} = PropertiesCount(props.Name);

    const prodata = removeDataAttributes(pro_data?.properties)
    const saleArray = [];
    prodata?.map((data) => {
        if(data.status === 'for sale') {
            saleArray.push(data)
        }
    })
    const rentArray = [];
    prodata?.map((data) => {
        if(data.status === 'rented' || data.status === 'for rent') {
            rentArray.push(data)
        }
    })
    const pro_count = prodata ? prodata?.length : 0;

    // const {loading:loading, error:error, data:data} = SubCommunityDetails("West Bay");

    // const TitleArray = [];
    // data?.communityDistricts.map(list => {
    //         TitleArray.push({
    //             Title: list.Name,
    //             Content: list.Intro_Content[0].Intro_Text
    //         })
    // })

    // const WestbayAccordianList = [...props.Accordian_List, ...TitleArray];
    let ListArray;
    ListArray = props.Accordian_List
    // if (props.Name == "West Bay") {
    //     ListArray = WestbayAccordianList
    // } else {
    //     ListArray = props.Accordian_List
    // }
   const location = useLocation();
   const thePath  = location.pathname;
   const pathName = thePath.replace("/about/our-communities/","");
   const baseName = pathName.split("/");
   /*console.log("baseName: ",baseName);
   console.log("length:- ",baseName.length);*/
   let indexVal = parseInt((baseName.length)-1);
   if(!baseName[indexVal]) {
     indexVal = indexVal-1;
   }
   const actUrl = baseName[indexVal];
   const con_name = actUrl.replaceAll("-"," ");
   let btnName = "";
   if(con_name){
     btnName = con_name.toLowerCase().replace(/\b[a-z]/g, function(letter) {
       return letter.toUpperCase();
     });
   }
   /*console.log("Index Val:-",indexVal);
   console.log("Comm:- ",con_name);
   console.log("btnName:-",btnName);*/

    let path_name = (thePath).split('/');
    let get_url = path_name[path_name.length-1] !== "" ? path_name[path_name.length-1] : path_name[path_name.length-2];
    const {loading:loadings, error:errors, data:datas} = CommunityDetails(get_url);
    const {loading:district_loading, error:district_error, data:district_data} = CommunityDistrict(get_url);

    useEffect(() => {
        datas && datas?.length > 0 && setDetails(datas?.[0]);
    },[datas]);

    useEffect(() => {
        district_data && district_data.communityDistricts.length > 0 && setDetails(district_data.communityDistricts[0]);
    },[district_data]);

    useEffect(() =>{
        if(window && userAgent == null){
            setUserAgent(window?.navigator.userAgent)
        }
    },[])

    var sale_hyperlink = details?.sale_button_link ? details.sale_button_link : `/properties/for-sale/in-${actUrl}`;
    var sale_button = details?.sale_button_label ? details.sale_button_label : `Properties for Sale in ${btnName}`;
    var rent_hyperlink = details?.rent_button_link ? details.rent_button_link : `/properties/for-rent/in-${actUrl}`;
    var rent_button = details?.rent_button_label ? details.rent_button_label : `Properties for Rent in ${btnName}`;
    
    let Name = props?.Office_Details?.Name;

    return (
        <div className={`contact-info ${props.classNames}`}>
            <Container>
                <Row className="contact-info-row-wrap">
                    <Col xl={5}>
                         <div className="animated">
                            <h2>{props.Title}</h2>
                            {
                                offficeData &&
                                <div className="negotiator d-flex justify-content-between">
                                    <figure>
                                        {Name != "Riya" ? <ImageModule ImageSrc={offficeData.Image} /> : <img src={logo} alt="profile" />}
                                    </figure>
                                    <div className="info">
                                        {Name != "Riya" && <strong className="name d-block">{offficeData.Name}</strong>}
                                        <div className="contact-nav">
                                            <a href={`tel:${offficeData.Direct_Phone}`}>{offficeData.Direct_Phone}</a>
                                            <span>|</span>
                                            <Link to={props.ContactLink ? props.ContactLink : "/our-offices/real-estate-agents-in-qatar/"}>Contact Office</Link>
                                        </div>
                                    </div>
                                </div>
                            }
                             {location.pathname.indexOf('our-communities') >= 0 && pro_count>0 && location.pathname.split("/").length <= 5 &&
                            <div class="browse-btn"><span>Browse:</span>
                              <div class="btn-block">
                                {saleArray.length > 0 &&
                              <a class="btn btn-primary" href={sale_hyperlink}><span>{sale_button}</span></a>}
                                {rentArray.length > 0 &&
                              <a class="btn btn-primary" href={rent_hyperlink}><span>{rent_button}</span></a>}
                                </div>
                            </div>
                            }
                         </div>
                    </Col>
                    <Col xl={7}>
                         <div className="animated">
                            {/* <div className={`read-more-wrap ${readMore ? "showless" : "showall"}`}>
                               <p className={`desc`}>{HTMLReactParser(props.Content.replace(/\n/g, '<br />'))}</p>  
                            </div>  */}
                         
                         {/* <p className="desc">
                            {readMore ? HTMLReactParser(props.Content.slice(0, 650)) : HTMLReactParser(props.Content)} 
                         </p> */}
                         <p className={` desc my-desc-content ${showFullContent || isbot(userAgent) === true ? 'full-content' : ''}`}>
                            <div dangerouslySetInnerHTML={{ __html: props.Content }} />
                         </p>
                            {props.AccordionItems && props.AccordionItems.length > 0 && <AccordionBlock AccordionItems={props.AccordionItems} Collection={props.Collection} />}

                            {props?.Office_Details?.Accordian_Title &&
                                <div className="info">
                                    <strong className="name d-block">{offficeData.Accordian_Title}</strong>
                                </div>
                            }

                            <div className="desc">
                                {
                                    props.Services_List && props.Services_List.length > 0 && (props.Content.length < 650  || readMore === false) && <>
                                        <p className="accordian_title">{props.Services_Title}</p>
                                        <ul className="accordian_listview">
                                            {props.Services_List.map(servicelist => {
                                                    // console.log("$$$", servicelist.Service_Text)

                                                    return <>
                                                        <li><img src={servicelist.Service_Icon.url} /> <span>{servicelist.Service_Text}</span></li>
                                                    </>
                                                })
                                            }
                                        </ul>
                                    </>
                                }


                                {
                                    ListArray &&  ListArray.length > 0 && (props.Content.length < 650  || readMore === false|| isbot(userAgent) === true) &&
                                     <div className="animated">
                                        <p className="accordian_title">{props.Accordian_Title}</p>

                                        <Accordion defaultActiveKey={activeId}>
                                            {
                                                ListArray.length > 0 && ListArray.map((item, index) => {
                                                    return (
                                                        <Card>
                                                            <Accordion.Toggle onClick={() => toggleActive(index.toString())} className={`accordion-header`} eventKey={index.toString()}>
                                                                {item.Title}
                                                                <i className={activeId === index.toString() ? "icon-minus-accordion" : "icon-plus-accordion"}></i>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={index.toString()}>
                                                                <div className="accordion-card">
                                                                    <p>{item.Content && HTMLReactParser(item.Content)}</p>
                                                                </div>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    )
                                                })
                                            }
                                        </Accordion>
                                     </div>
                                }

                            </div>
                            <div className="desc">
                            
                            {props.Content.length > 850 &&
                             <span onClick={toggleDescContent} className="read-more-less">
                                {showFullContent || isbot(userAgent) === true ? ' - Read Less' : ' + Read More'}
                             </span> }
                          </div>  
                         </div>
                    </Col>
                </Row>

            </Container>
        </div>)
}



export default ContactInfo
