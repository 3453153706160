import React, { useState, useEffect} from "react"
import {Container, Row, Col, Badge, Nav,Card} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import "./PropertyFacts.scss"
import HTMLReactParser from 'html-react-parser';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import $ from 'jquery';
// styles

// markup
const ProjectFacts = ( props ) => {
  
  const propertyfacts = props?.data

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLighboxMobile = (e, ind) => {
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  }   

  var lightImages2 = propertyfacts.floor_plans && propertyfacts.floor_plans.map(img => img.url);  

  return (
  <React.Fragment>
    <>   
        <div className="property-facts-section" id="project_facts">
            <div className="animated">
              <div className="property-facts-title">
                {propertyfacts?.facts_title &&
                <h3>{propertyfacts.facts_title}</h3>
                }
                {propertyfacts?.facts_content &&
                <div className="description">{HTMLReactParser(propertyfacts.facts_content)}</div>
                }
              </div>
              <div className="project-fact-list">
                <ul>
                 {propertyfacts?.location &&            
                  <li>
                      <strong>Location</strong>
                      {propertyfacts?.location}
                  </li>
                  } 
                  {propertyfacts?.Ownership &&            
                  <li>
                      <strong>Ownership</strong>
                      {propertyfacts?.Ownership}
                  </li>
                  }
                  {propertyfacts?.Price_Starting_From &&            
                  <li>
                      <strong>Price (starting from):</strong>
                      {propertyfacts?.Price_Starting_From}
                  </li>
                  }               
                </ul>

                </div>
              </div>
        </div>
        {propertyfacts?.Key_Features &&
          <div className="property-facts-section properties-facts-section" id="details_key_features">
              <div className="">
                <div className="property-facts-title">
                  <h2>Details & Key Features</h2>
                  {propertyfacts?.Key_Features &&
                  <div className="description">{HTMLReactParser(propertyfacts.Key_Features)}</div>
                  }
                </div>               
                </div>
          </div>
        }
        {propertyfacts?.Development_Strategy &&
          <div className="property-facts-section properties-facts-section" id="development_strategy">
              <div className="">
                <div className="property-facts-title">
                  <h2>Development & Strategy</h2>
                  {propertyfacts?.Development_Strategy &&
                  <div className="description">{HTMLReactParser(propertyfacts.Development_Strategy)}</div>
                  }
                </div>               
                </div>
          </div>
        }
        {propertyfacts?.Payment_Plan_Modules &&
          <div className="property-facts-section properties-facts-section" id="our_payment_plan">
              <div className="">
                <div className="property-facts-title">
                  <h2>Our Payment Plan</h2>
                  <div className="payment_plan">
                  {propertyfacts?.Payment_Plan_Modules && propertyfacts?.Payment_Plan_Modules?.length > 0 && propertyfacts?.Payment_Plan_Modules.map((item, index) => {
                      return(                        
                        <div className="property-toolkit-item">
                          <div className="property-toolkit-info">
                            <h2>{item?.Schedule_of_payments}</h2>
                            <h3>{item?.Payment_Installments}</h3>
                            
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                </div>               
                </div>
          </div>
        }
         {propertyfacts?.floor_plans &&
          <div className="property-facts-section properties-facts-section" id="floorplans">
              <div className="">
                <div className="property-facts-title">
                  <h2>Floorplans</h2>
                  <div className="property-area-map">
                      {propertyfacts?.floor_plans && propertyfacts?.floor_plans.map((item, index) => 
                          <div className="property-area-map-list" key={index}>
                              <div className="property-area-img" onClick={(e) => openLighboxMobile(e, index)}>
                                  <img src={item.url} alt="" />
                              </div>
                          </div>
                      )}
                  </div>
                </div>               
              </div>
          </div>
        }   
   </>

   {isOpen && (
          <Lightbox
            mainSrc={lightImages2[photoIndex]}
            nextSrc={lightImages2[(photoIndex + 1) % lightImages2.length]}
            prevSrc={lightImages2[(photoIndex + lightImages2.length - 1) % lightImages2.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + lightImages2.length - 1) % lightImages2.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % lightImages2.length)
            }
          />
        )}

  </React.Fragment>
  )
}

export default ProjectFacts