import { Link } from "@StarberryUtils";
import * as React  from "react"
import { useState } from "react"
import { Row, Container, Col, Nav, Tab } from "react-bootstrap"
import Slider from "react-slick"
import HTMLReactParser from 'html-react-parser';
import Modal from "react-bootstrap/Modal"
// Images

import { ImageModule, SourceBlock } from '../../../modules/Image_Module';
import InteriorForm from "../../../components/forms/interior-form"

import "./PropertyOwner.scss"
// markup


const PropertyOwner = (props) => {
  const [isOpen,setIsOpen] = useState(false);
  const [form_lables, setFormlables] = useState("");
  const [subject, setSubject] = useState("");
  const [formdata, setFormdata] = useState("");
  const [typeVal, setTypeVal] = useState("");
  
  const openModalForm = (e, item) => {
    e.preventDefault();
    //console.log("modal_heading", item);
    setSubject(item.Title)
    setTypeVal(item.Title)
    setFormdata(item)
    setIsOpen(true);
    //setProperty_data(item)
    //setFormlables(item.name)
    //setModalHeading(modal_heading);
    //setModalType(modal_type);
  }
  return (
    <div className="property-owner-block">
      <Container>
        <Row>
          <Col>
            
            <div className="home-worth-box">

              
                {
                  props.Tiles_Blocks && props.Tiles_Blocks.length > 0 && props.Tiles_Blocks.map((item, index) => {
                    return(
                       <div className="animated">
                      <div className="home-worth-item">
                        <div className="home-worth-img">
                          <picture>
                            {item.Image ? <ImageModule classNames={""} ImageSrc={item.Image} /> : '' }
                          </picture>
                        </div>
                        <div className="home-worth-info">
                          <h2>{item.Title}</h2>
                          {item.Content && HTMLReactParser(item.Content)}
                           {item.CTA_1_URL === "snagging_form" ? 
                            <a href="javascript:;" className="btn btn-primary d-none d-md-inline-block" onClick={(e) => props.openModalForm(e, 'Snagging & Handover - '+item.CTA_1_Label)}><span>{item.CTA_1_Label}</span></a> 
                          : 
                          <a href="javascript:;" className="btn btn-primary d-none d-md-inline-block" onClick={(e) => openModalForm(e, item)}><span>{item.CTA_1_Label}</span></a> 
                           }
                          {/*item.CTA_1_URL === "snagging_form" ? 
                            <a href="javascript:;" className="btn btn-primary d-none d-md-inline-block" onClick={(e) => props.openModalForm(e, 'Snagging & Handover - '+item.CTA_1_Label)}><span>{item.CTA_1_Label}</span></a> 
                          : 
                            <Link to={item.CTA_1_URL ? item.CTA_1_URL : "#"} className="btn btn-primary">
                              <span>{item.CTA_1_Label}</span>
                            </Link>
                          */ }
                        </div>
                      </div>
                       </div>
                    )
                  })
                }
                
              

            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        id="valuation_modal"
        show={isOpen}
        onHide={() => setIsOpen(false)}
        backdrop="static"
        keyboard={false}
        className="member-contact-modal"
      >
      {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
      <Modal.Header closeButton className="contact-close-btn memberPopup">
        <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">{"Interior Form " + subject}</h3></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InteriorForm to_email_id={`decor@thepearlgates.com`} 
        formName = {"Interior Form " + subject}
        formLabel = {"Interior Form " + subject}
        formType = {"Interior Form " + subject}
        subjectName = {"Interior " + subject}
        formdata = {formdata}
        typeVal={typeVal}
        />
      </Modal.Body>
     </Modal>



    </div>
  )
}

export default PropertyOwner
