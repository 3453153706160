import React,{useEffect, useState} from "react"
import {Container, Row, Col, Badge, Nav,Card} from 'react-bootstrap';
import $ from 'jquery';
import ScrollAnimation from 'react-animate-on-scroll';
import "./PropertyFacts.scss"

// styles

// markup
const ProjectFacts = ( props ) => { 

  const [activeTab, setActiveTab] = useState('#details_key_features')


  const handleClick = (val) => {
    setActiveTab(val)
    const element = document.querySelector(val);
    if (element) {
        window.scrollTo({
            behavior: 'smooth',
            top:
                element.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                150,
        })
    }
}
  useEffect(() => {
    $(document).ready(function () {
      // $(".tab-switch ul li a").click(function (e) {
      //   e.preventDefault();
      //   var aid = $(this).attr("href");
      //   $('html, body').animate({
      //     scrollTop: $(aid).offset().top - ($(".header").outerHeight() + $(".tab-switch").outerHeight() + 50)
      //   }, 1000);
      //   $(this).parent().addClass('active').siblings().removeClass('active');


      // });

      // $(window).on('scroll', function () {
      //   if ($(window).width() > 992) {
      //       if ($(".main-banner-embed")[0]?.getBoundingClientRect()?.bottom < 0) {
      //         $('.tab-switch').addClass('stick');
      //       } else {
      //         $('.tab-switch').removeClass('stick');
      //       }
      //     }else{
      //       if ($(".PropertyFactsList-RegisterForm")[0]?.getBoundingClientRect()?.bottom < 0) {
      //         $('.tab-switch').addClass('stick');
      //       } else {
      //         $('.tab-switch').removeClass('stick');
      //       }
      //     }
      // });


        if (window) {
          $(window).on('scroll', function () {
                var windscroll = $(window).scrollTop();
                if (windscroll >= 200) {
                    $('.properties-facts-section').each(function (i) {
                        if ($(this).position().top + 600 <= windscroll) {
                            $('.tab-switch li.active').removeClass('active');
                            $('.tab-switch li').eq(i).addClass('active');
                        }
                    });
                } else {
                    $('.tab-switch li.active').removeClass('active');
                    $('.tab-switch li:first-child').addClass('active');
                }
            }).scroll();
        }




    });




  }, []);

 
  return (
  <React.Fragment>
    <>
    <Nav className="tab-switch secondary-nav">
          <Container>
            <Row>
              <ul className="d-flex col-lg-12">
                {props?.data.facts_content &&
                <li className={activeTab === "#project_facts" ? 'active' : ''}>
                  <a href="javascript:;" onClick={(e) => handleClick("#project_facts")}>Project Facts</a>
                </li>
                }
                {props?.data.Key_Features &&
                  <li className={activeTab === "#details_key_features" ? 'active' : ''}>
                      <a href="javascript:;" onClick={(e) => handleClick("#details_key_features")}>Details & Key Features</a>
                  </li>
                }
                {props?.data.Development_Strategy &&
                  <li className={activeTab === "#development_strategy" ? 'active' : ''}>
                      <a href="javascript:;" onClick={(e) => handleClick("#development_strategy")}>Development & Strategy</a>
                  </li>
                }
                {props?.data.Payment_Plan_Modules &&
                  <li className={activeTab === "#our_payment_plan" ? 'active' : ''}>
                     <a href="javascript:;" onClick={(e) => handleClick("#our_payment_plan")}>Our Payment Plan</a>
                  </li>
                }
                {props?.data.floor_plans &&
                  <li className={activeTab === "#floorplans" ? 'active' : ''}>
                      <a href="javascript:;" onClick={(e) => handleClick("#floorplans")}>Floorplans</a>
                  </li>
                }               
              </ul>
            </Row>
          </Container>
        </Nav>
   </>
  </React.Fragment>
  )
}

export default ProjectFacts