import React, { useState, useEffect, useRef, createRef } from "react"
import { Helmet } from "react-helmet";
import { Container, Row, Card, Col } from "react-bootstrap";
import axios from "axios"  
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/Interior/Banner/Banner";
import ContactInfo from "../components/Home/ContactInfo/ContactInfo";
import Portfolio from "../components/Interior/Portfolio/Portfolio"; 
import ContentBlock from "../components/Content/Content" 
import FilterBlock from "../components/StaticOne/FilterBlock/FilterBlock" 
import GridBlock from "../components/Mortgage/Grid-block/Grid-block"
import InteriorBlock from "../components/Interior/Blocks/GetStarted"  
import GetStarted from "../components/GetStarted/GetStarted"
import GoogleReviews from "../components/Review/GoogleReviews/InteriorGoogleReviews" 
import PropertyOwner from "../components/StaticTwo/PropertyOwner/PropertyOwner"
import LatestBlogsbyCategory from "../components/StaticOne/FilterBlock/LatestBlogsbyCategory"
import wordsToNumbers from 'words-to-numbers';
import { removeDataAttributes } from "../comQueryStructure";
import { google_review_url } from "../components/common/utils";
 
  
function InteriorTemplate(props) {


  const find_more_ref = createRef();

  const [testimonials, setTestimonials] = useState([]);


  const strapiconfig = {
    headers: {
      Authorization:
        `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }

  useEffect(() => {
    let url = google_review_url
    getitems(url);
  }, []);


  var ggl_reviews = [];
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files
      const reviewdata = removeDataAttributes(data)
      if (reviewdata && reviewdata.length > 0) {
        reviewdata.map((item, index) => {
          var star_no = wordsToNumbers(item.starRating);
          if (star_no > 3) {
            if (item.comment) {
              ggl_reviews.push(item);
            }
          }

        })
      }
      setTestimonials(ggl_reviews);
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }






  const findoutMore = () => {
    find_more_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
 
  var GQLPage = props.GQLPage;
  var GQLModules = props.GQLModules;

  var BannerImage = GQLPage && GQLPage.Header_Banner_Image;
  var BannerVideo = GQLPage && GQLPage.Header_Banner_Video;

  let processedImages = JSON.stringify({});
  if (GQLPage?.imagetransforms && GQLPage?.imagetransforms.Header_Banner_Image_Transforms) {
    processedImages = GQLPage?.imagetransforms.Header_Banner_Image_Transforms;
  }
  const openModalForm = (e, modal_heading, modal_type) => {
    e.preventDefault(); 
   
  }


  var main_cnt_cls = (GQLPage?.Layout !== "Default" && GQLPage?.Layout !== "Buy_Landing_Page") ? "page-content" : '';
  main_cnt_cls += (GQLPage?.Layout === "Rightside_Block") ? " static-page" : '';

  return (
    <>
      {
        props.GQLPage && (
          <Layout Layout={`Without_Banner`} classNames={GQLPage.Layout} popular_search={GQLPage.popular_search} Alias={GQLPage.Alias} Select_Popular_Search={GQLPage.Select_Popular_Search}  Pagename={props.GQLPage?.Pagename}>

            <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} />

            <Helmet bodyAttributes={{ class: `homepage ${GQLPage ? GQLPage.Layout : ''} ${GQLPage ? GQLPage.Custom_CSS_Class : ''}` }}>
              <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            </Helmet>

            {/* ====== Filter Header Banner ===== */}

            {
              GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModHeaderBanner" || "GLSTRAPI_ComponentComMultifamilyHeader").map((Modules, i) => {

                var image_name = (GQLPage.Layout === "Content_Full_Width" || GQLPage.Layout === "Rightside_Block") ? 'article.Header_Banner_Image.inner_header' : 'article.Header_Banner_Image.header';

                return (
                  <>
                    {/* ======= Header Animate Banner Modules ====== */}
                    {BannerImage && Modules.Header_Title &&
                      <Banner Banner_Title={Modules.Header_Title && Modules.Header_Title} CTA_1_Label={Modules.CTA_1_Label && Modules.CTA_1_Label} CTA_1_URL={Modules.CTA_1_URL && Modules.CTA_1_URL} CTA_2_Label={Modules.CTA_2_Label && Modules.CTA_2_Label} CTA_2_URL={Modules.CTA_2_URL && Modules.CTA_2_URL} Banner_Image={BannerImage} Banner_Video={BannerVideo} Content={GQLPage.Content} Layout={GQLPage.Layout} openModalForm={openModalForm} imagename={image_name} article_id={GQLPage.id} imagetransforms={GQLPage.ggfx_results} handleClick={findoutMore} GQLModules={GQLModules} Show_Banner_Google_Review={Modules.Show_Banner_Google_Review} testimonials={testimonials} Alias={GQLPage.Alias} Show_Enquire_Form={Modules.Show_Enquire_Form} Form_Heading={Modules.Form_Heading} Play_Button={GQLPage.Play_Button} location={props.location} description={GQLPage.Meta_Description} template={"common"} />
                    }

                    {Modules.__typename === "GLSTRAPI_ComponentComGridBlock" &&
                      <GridBlock {...Modules} />
                    }

                  </>
                )
              })
            }


            {

              <div className="">

                <div className={`${main_cnt_cls} portfolio-section ${GQLPage.Page_CSS_Class ? GQLPage.Page_CSS_Class : ''}`} ref={find_more_ref}>
                  <Container>
                    {/* ====== Main Container Modules ===== */}
                    {GQLModules.map((Modules, i) => {
                      return (
                        <>
                          {/* ======= Tiles  Section ====== */}
                          {Modules.__typename === "GLSTRAPI_ComponentModCollectionBlock" &&
                            <Portfolio {...Modules} />
                          }
                        </>
                      )
                    })}
                  </Container>
                </div>

                <div className={`${main_cnt_cls} ${GQLPage.Page_CSS_Class ? GQLPage.Page_CSS_Class : ''}`} ref={find_more_ref}>
                  <Container>
                    {/* ====== Main Container Modules ===== */}
                    {GQLModules.map((Modules, i) => {
                      return (
                        <>
                          {(GQLPage.Layout !== "Default" && GQLPage.Layout !== "Buy_Landing_Page") && Modules.Show_Insights && <FilterBlock Filter_by_Tag={Modules.Filter_by_Tag ? Modules.Filter_by_Tag : ''} />}

                          {/* ======= Intro Section ====== */}
                          {Modules.Intro_Title && Modules.Intro_Text &&
                            <ContactInfo Title={Modules.Intro_Title} Content={Modules.Intro_Text} Office_Details={Modules.Choose_Office && Modules.Choose_Office} AccordionItems={Modules.Add_Accor_Item && Modules.Add_Accor_Item.length > 0 && Modules.Add_Accor_Item} Accordian_Title={Modules.Intro_Accordian_Title} Accordian_List={Modules.Intro_Accordian_List} Services_Title={Modules.Intro_Services_Title} Services_List={Modules.Intro_Services_List} Service_Text={Modules.Service_Text} Service_Icon={Modules.Service_Icon} />
                          }

                          {/* ======= Interior Block Section ====== */}
                          {Modules.__typename === "GLSTRAPI_ComponentComInteriorBlock" &&
                            <InteriorBlock {...Modules} />
                          }

                          {/* ======= Get Started Block Section ====== */}
                          {Modules.__typename === "GLSTRAPI_ComponentModGetStarted" &&
                            <>
                              <div className={`left-right-module-title ${Modules.Get_Started_Title.toLowerCase().replace(/ /g, '-')}`}><h2>Why Choose us</h2></div>
                              <GetStarted Get_Started_Block={Modules} pageAlias={GQLPage.Alias} />
                            </>
                          }
                        </>
                      )
                    })}
                  </Container>
                </div>

                <div className={`${main_cnt_cls} packages-section ${GQLPage.Page_CSS_Class ? GQLPage.Page_CSS_Class : ''}`} ref={find_more_ref}>
                  <Container>
                    {/* ====== Main Container Modules ===== */}
                    {GQLModules.map((Modules, i) => {
                      return (
                        <>
                          {/* ======= Inner Page Content Block ====== */}
                          {GQLPage.Alias !== "careers" && GQLPage.Alias !== "reviews" && Modules.__typename === "GLSTRAPI_ComponentModContentBlock" &&
                            <ContentBlock Content={Modules.Content} Content_Heading_Text={Modules.Title} classNames={GQLPage.Layout} />
                          }

                          {/* ======= Snaging Tiles Block Section ====== */}
                          {Modules.Snagging_Tiles && Modules.Snagging_Tiles.length > 0 &&
                            <>
                              <div className="packages-title"><h2>Our Packages</h2></div>
                              <PropertyOwner Tiles_Blocks={Modules.Snagging_Tiles} />
                            </>
                          }                          
                        </>
                      )
                    })}
                  </Container>
                </div>

              </div>
            }
            <GoogleReviews Title={`Reviews`} Content={`<p><span style="font-weight: 400;">We take our client feedback seriously, ensuring we are constantly improving our service and offerings for our ever-growing client base both locally and internationally.</span></p>
<p><span style="font-weight: 400;">Take a look at what our customers are saying about The Pearl Gates.</span></p>`} testimonials={testimonials} isPage={false} />

               <div className={`${main_cnt_cls} packages-section ${GQLPage.Page_CSS_Class ? GQLPage.Page_CSS_Class : ''}`} ref={find_more_ref}>
                  <Container>
                    {/* ====== Main Container Modules ===== */}
                    {GQLModules.map((Modules, i) => {
                      return (
                        <>
                         {Modules.__typename =="GLSTRAPI_ComponentModBlogCategoriesList" &&
                            <div className="">
                              <LatestBlogsbyCategory Filter_by_Tag={Modules} /> 
                            </div>
                         }
                        </>
                      )
                    })}
                  </Container>
                </div>
          </Layout>

        )
      }
    </>
  )
}

export default InteriorTemplate
