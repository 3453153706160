import * as React from "react"
import GoogleSingleMap from "../maps/google/single"
import "./Map.scss"
// styles

// markup
const Map = (props) => {
  
  //console.log("Latitude", typeof props.Latitude, props.Longitude);
  return (

  <React.Fragment>
    <div className="map-wrap">
     <div className="animated"> 
      {/* <picture>
        <source media="(min-width:992px)" srcSet={MapImg}/>
        <source media="(min-width:768px)" srcSet={MapImgTablet}/>
        <img src={mapMobile} alt=""/>
      </picture> */}
      <GoogleSingleMap lat={props.Latitude ? parseFloat(props.Latitude) : ''} lng={props.Longitude ? parseFloat(props.Longitude) : ''} height={"500px"} />
      </div>
    </div>
  </React.Fragment>
  )
}

export default Map