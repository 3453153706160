import { Link } from "@StarberryUtils";
import React, {useEffect, useState} from "react"
import { Row, Container, Col, Nav, Tab } from "react-bootstrap"
import Slider from "react-slick"

import Modal from "react-bootstrap/Modal"
import {ImageModule} from "../../../modules/Image_Module";
import ValuationForm from "../../forms/valuation-form"
import "./HomeWorthBlock.scss"
import bgCurve from "../../../images/home/pg-logo-curve.webp"

const HomeWorthBlock = (props) => {
  const [isOpen,setIsOpen] = useState(false);
  const [val_form_name,setValFormName] = useState('');
  const [val_type,setValType] = useState('');

  const openValForm = (val_form_name, val_type) => {
    setValFormName(val_form_name);
    setValType(val_type);
    setIsOpen(true);

  }

  return (
    <div className="home-worth-block">
      <span className="bg-curve"><img src={bgCurve} alt="bg-img" /></span>
      <Container>
        <Row>
          <Col>
            <div className="home-worth-heading">
               <div className="animated">
                <h1>{props.Title}</h1>
                <p>
                  {props.Content}
                </p>
               </div>
            </div>
            <div className="home-worth-box">
              {
                props.TilesBlock && props.TilesBlock.map((item, index)=>{
                  return(                    
                      <div className="home-worth-item">
                         <div className="animated" delay={`${index}00`} >
                        <div className="home-worth-img">
                          <picture>
                            {/* <source
                              media="(min-width:992px)"
                              srcSet={HomeWorthImgDesktop}
                            />
                            <source
                              media="(min-width:768px)"
                              srcSet={HomeWorthImgTablet}
                            />
                            <img src={HomeWorthImg} alt="" /> */}
                            <ImageModule ImageSrc={item.Tile_Image}/>
                          </picture>
                        </div>
                        <div className="home-worth-info">
                          <h3>{item.Title}</h3>
                          <p>
                            {item.Content}
                          </p>
                          <a href="javascript:;" onClick={() => openValForm(item.Title, `${index+1}`)} className="btn btn-primary">
                            <span>{item.CTA_1_Label}</span>
                          </a>
                        </div>
                         </div>
                      </div>
                    
                  )
                })
              }
              
            </div>
          </Col>

          <Modal
            id="valuation_modal"
            show={isOpen}
            onHide={() => setIsOpen(false)}
            backdrop="static"
            keyboard={false}
            className="member-contact-modal"
          >
            {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
            <Modal.Header closeButton className="contact-close-btn memberPopup">
              <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0 ">Valuation Form - {val_form_name}</h3>
                <h5>{val_type === "1" ? "To value a villa or a building" : val_type === "2" ? "To estimate all type of properties" : "To generate GRM (Gross Rent Multiple)"}</h5>
              </Modal.Title>
              
            </Modal.Header>
            <Modal.Body>
              <ValuationForm formName={`${val_form_name}`} formLabel={`${val_form_name}`} valuationType={val_type} />
            </Modal.Body>
          </Modal>

        </Row>
      </Container>
    </div>
  )
}

export default HomeWorthBlock
