import React, { useState, useEffect, useRef, createRef } from "react"
import { Helmet } from "react-helmet";
import { Container, Row, Card, Col } from "react-bootstrap";
import axios from "axios"
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/Mortgage/Banner/Banner";
import GridBlock from "../components/Mortgage/Grid-block/Grid-block";
import ListBlock from "../components/Mortgage/List-block/List-block";
import LatestDeals from "../components/Mortgage/Deals/Deals-block";
import ContactInfo from "../components/Home/ContactInfo/ContactInfo";
import ContentBlock from "../components/Content/Content"
import Reviews from "../components/Home/Reviews/Reviews"
import GoogleReviews from "../components/Review/GoogleReviews/InteriorGoogleReviews"
import LatestBlogsbyCategory from "../components/StaticOne/FilterBlock/LatestBlogsbyCategory"
import BottomBlock from "../components/BottomBlock/BottomBlock"
import wordsToNumbers from 'words-to-numbers';
import { TickerTape } from "react-ts-tradingview-widgets";
import { removeDataAttributes } from "../comQueryStructure";
import { google_review_url } from "../components/common/utils";

function MortgageTemplate(props) {


  const find_more_ref = createRef();

  const [testimonials, setTestimonials] = useState([]);


  const strapiconfig = {
    headers: {
      Authorization:
        `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }

  useEffect(() => {
    let url = google_review_url
    getitems(url);
  }, []);


  var ggl_reviews = [];
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files
      const reviewdata = removeDataAttributes(data)
      if (reviewdata && reviewdata.length > 0) {
        reviewdata.map((item, index) => {
          var star_no = wordsToNumbers(item.starRating);
          if (star_no > 3) {
            if (item.comment) {
              ggl_reviews.push(item);
            }
          }

        })
      }
      setTestimonials(ggl_reviews);
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }






  const findoutMore = () => {
    find_more_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  var GQLPage = props.GQLPage;
  var GQLModules = props.GQLModules;

  var BannerImage = GQLPage && GQLPage.Header_Banner_Image;
  var BannerVideo = GQLPage && GQLPage.Header_Banner_Video;

  let processedImages = JSON.stringify({});
  if (GQLPage?.imagetransforms && GQLPage?.imagetransforms.Header_Banner_Image_Transforms) {
    processedImages = GQLPage?.imagetransforms.Header_Banner_Image_Transforms;
  }
  const openModalForm = (e, modal_heading, modal_type) => {
    e.preventDefault();

  }


  var main_cnt_cls = (GQLPage?.Layout !== "Default" && GQLPage?.Layout !== "Buy_Landing_Page") ? "page-content" : '';
  main_cnt_cls += (GQLPage?.Layout === "Content_Full_Width" || GQLPage?.Layout === "Rightside_Block") ? " static-page" : '';

  //console.log('mkpage', GQLPage)
  return (
    <>
      {
        props.GQLPage && (
          <Layout Layout={``} classNames={`${GQLPage.Layout} ${GQLPage.Page_CSS_Class ? GQLPage.Page_CSS_Class+'-wrapper' : ''}`} popular_search={GQLPage.popular_search} Alias={GQLPage.Alias} Select_Popular_Search={GQLPage.Select_Popular_Search} Pagename={props.GQLPage?.Pagename}>

            <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} />

            <Helmet bodyAttributes={{ class: `homepage ${GQLPage ? GQLPage.Layout : ''} ${GQLPage ? GQLPage.Custom_CSS_Class : ''}` }}>
              <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            </Helmet>

            {/* ====== Filter Header Banner ===== */}

            {
              GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModHeaderBanner" || "GLSTRAPI_ComponentComMultifamilyHeader").map((Modules, i) => {

                var image_name = 'article.Header_Banner_Image.inner_header';

                return (
                  <>
                    {/* ======= Header Animate Banner Modules ====== */}
                    {BannerImage && Modules.Header_Title &&
                      <Banner Banner_Title={Modules.Header_Title && Modules.Header_Title} CTA_1_Label={Modules.CTA_1_Label && Modules.CTA_1_Label} CTA_1_URL={Modules.CTA_1_URL && Modules.CTA_1_URL} CTA_2_Label={Modules.CTA_2_Label && Modules.CTA_2_Label} CTA_2_URL={Modules.CTA_2_URL && Modules.CTA_2_URL} Banner_Image={BannerImage} Banner_Video={BannerVideo} Content={Modules.Content} Layout={GQLPage.Layout} openModalForm={openModalForm} imagename={image_name} article_id={GQLPage.id} imagetransforms={GQLPage.ggfx_results} handleClick={findoutMore} GQLModules={GQLModules} Show_Banner_Google_Review={Modules.Show_Banner_Google_Review} testimonials={testimonials} Alias={GQLPage.Alias} Show_Enquire_Form={Modules.Show_Enquire_Form} Form_Heading={Modules.Form_Heading} Play_Button={GQLPage.Play_Button} location={props.location} description={GQLPage.Meta_Description} template={"common"} banner_class=""/>
                    }
                  </>
                )
              })
            }
            <TickerTape colorTheme="dark" ></TickerTape>

            {

              <div className={`${main_cnt_cls} ${GQLPage.Page_CSS_Class ? GQLPage.Page_CSS_Class : ''}`} ref={find_more_ref}>
                <div className="mortgage-content-wrapper">
                  {/* ====== Main Container Modules ===== */}
                  {GQLModules.map((Modules, i) => {
                    return (
                      <>


                        {/* ======= Inner Page Content Block ====== */}
                        {GQLPage.Alias !== "careers" && GQLPage.Alias !== "reviews" && Modules.__typename === "GLSTRAPI_ComponentModContentBlock" &&
                          <ContentBlock Content={Modules.Content} Content_Heading_Text={Modules.Title} classNames={GQLPage.Layout} />
                        }

                        {/* ======= Intro Section ====== */}
                        {Modules.Intro_Title && Modules.Intro_Text &&
                          <ContactInfo Title={Modules.Intro_Title} Content={Modules.Intro_Text} Office_Details={Modules.Choose_Office && Modules.Choose_Office} 
                          ContactLink= {Modules.Intro_CTA_Link &&  Modules.Intro_CTA_Link} AccordionItems={Modules.Add_Accor_Item && Modules.Add_Accor_Item.length > 0 && Modules.Add_Accor_Item} Accordian_Title={Modules.Intro_Accordian_Title} Accordian_List={Modules.Intro_Accordian_List} Services_Title={Modules.Intro_Services_Title} Services_List={Modules.Intro_Services_List} Service_Text={Modules.Service_Text} Service_Icon={Modules.Service_Icon} />
                        }

                        {/* ======= Tiles  Section ====== */}
                        {Modules.__typename === "GLSTRAPI_ComponentComGridBlock" &&
                          <GridBlock {...Modules} />
                        }
                        {Modules.__typename === "GLSTRAPI_ComponentComServiceContentBlock" &&
                          <ListBlock {...Modules} />
                        }
                        {props.GQLPage.Page_CSS_Class !== "mortgage-sub-services" && Modules.Show_Google_Review && <div className="d-md-flex flex-wrap"> <Reviews testimonials={testimonials} /> </div>}

                        {Modules.__typename === "GLSTRAPI_ComponentModCollectionBlock" && Modules.Collection_Name == "Mortgage_Deals" &&
                          <LatestDeals  {...Modules} />
                        }

                      </>
                    )
                  })}
                </div>
              </div>
            }

            {/** without container*/}
            {GQLModules.map((Modules, i) => {
              return (
                <>
                  {props.GQLPage.Page_CSS_Class === "mortgage-sub-services" && Modules.Show_Google_Review &&
                    <GoogleReviews Title={`Reviews`} Content={`<p><span style="font-weight: 400;">We take our client feedback seriously, ensuring we are constantly improving our service and offerings for our ever-growing client base both locally and internationally.</span></p>
  <p><span style="font-weight: 400;">Take a look at what our customers are saying about The Pearl Gates.</span></p>`} testimonials={testimonials} isPage={false} />
                  }
                  {/* ======= Bottom Content Block ====== */}
                  {Modules.__typename === "GLSTRAPI_ComponentModBottomBlock" &&
                    <BottomBlock Bottom_Block={Modules} openModalForm={openModalForm} />
                  }
                  {Modules.__typename =="GLSTRAPI_ComponentModBlogCategoriesList" &&
                    <div className="">
                      <LatestBlogsbyCategory Filter_by_Tag={Modules}  /> 
                    </div>
                  }

                </>
              )
            })}


          </Layout>

        )
      }
    </>
  )
}

export default MortgageTemplate
