import { Link } from "@StarberryUtils";
import React, { useState, useEffect, createRef } from "react"
import { Button, Container, Row, Col } from 'react-bootstrap';
import PlayVideo from "../../Play/PlayVideo"
import _ from "lodash";
import { ImageModule, SourceBlock } from '../../../modules/Image_Module';
import "./Banner.scss"
import $ from "jquery"
import HTMLReactParser from 'html-react-parser';
import { PopupButton } from "react-calendly";



const Banner = (props) => {

  const [isPlay, setPlay] = useState(false);
  const [render, setRender] = useState(false);
  const [youtubeId, setYoutubeId] = useState('');
  const videoRef = createRef(null);

  const playVideo = (url) => {
    setPlay(true);
    setYoutubeId(url)
    // videoRef.current.play();
  }

  const closeVideo = () => {
    setPlay(false);
    videoRef.current.pause();
  }

  var banner_cls = props.Layout === "Content_Full_Width" || props.Layout === "Rightside_Block" ? 'inner_banner' : '';
  const showPlayButton = () => {
    //console.log("content_over");
    $(".banner-btn").css('display', 'block')
  }
  useEffect(() => {
    if (!render) {
      setRender(true)
    }
  })
  return (
    <React.Fragment>
      <div className={`main-banner mortgage-banner ${banner_cls} `}>
        <div className="main-banner-embed">
          {
            props.Banner_Image &&
            <picture>
              <ImageModule classNames={"bg-img"} ImageSrc={props.Banner_Image} imagename={props.imagename} article_id={props.article_id} imagetransforms={props.imagetransforms} ggfxNew={true} />
            </picture>

          }

          <span class="overlay_bg"></span>

        </div>
        <div className={`main-banner-content ${props.Banner_Video ? "ban_video_text" : ""} ${props.Alias === 'contact-us' ? 'contact_banner' : ''}`} onMouseEnter={showPlayButton}>
          <Container>
            <div className="banner-text">
              <h1>{props.Banner_Title}</h1>
              <p className="desc">{props.Content && HTMLReactParser(props.Content)} </p>
              <Link to={props.CTA_1_URL ? props.CTA_1_URL : "#"} state={{ prevPath: props.location.pathname }} className="btn btn-primary"><span>{props.CTA_1_Label}</span></Link>
              {props.CTA_2_Label &&
              <Link to={props.CTA_2_URL ? props.CTA_2_URL : "#"} state={{ prevPath: props.location.pathname }} className="btn btn-primary "><span>{props.CTA_2_Label}</span></Link>
              }
              
            </div>
          </Container>
        </div>
      </div>
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={youtubeId}
        isAutoPlay={1}
      />

    </React.Fragment>
  )
}


export default Banner