import React, { useState, useEffect } from "react"
import PlayVideo from "../Play/PlayVideo";
import Slider from "react-slick"
import {Container, Row, Col, Badge, Nav,Card} from 'react-bootstrap';
import bgCurve from "../../images/home/pg-logo-curve.webp"
import HTMLReactParser from 'html-react-parser';
//const InteriorBanner = ({ Banner_Images, Video_URL }) => {

 const ProjectFactsSlider = (props) => {

  console.log("propsprops", props)

  const [isPlay, setPlay] = useState(false);
  // Slider
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    centerMode: true,
    swipeToSlide: true,
    variableWidth: true,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true, 
          arrows: false,
          centerMode: false,
          swipeToSlide: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true, 
          arrows: false,
          centerMode: false,
          swipeToSlide: false,
          variableWidth: false,
        },
      } 
    ]
  };

  return (
    <React.Fragment>
      <div className="new-property-banner">
        <div className="new-property-banner-content">
        <span className="bg-curve"><img src={bgCurve} alt="bg-img" /></span>
          <Container>
            <div className="text-content">
              {props?.Image_slider_title &&
              <h2>{props?.Image_slider_title}</h2>
              }
              {props?.image_slider_contents &&
                  <div className="description">{HTMLReactParser(props?.image_slider_contents)}</div>
              }
            </div>
          </Container>
        </div>
        <div className="col-img">
          {props?.Upload_Images && <>
            <Slider {...settings} >
              {
                props?.Upload_Images.map(sliderimg => {
                  return <img src={sliderimg.url} alt="" />
                })
              }
            </Slider>
          </>
          }
          {
            /*Video_URL &&
            <strong className="play-btn-icon" onClick={e => { setPlay(true) }}>
              <i className="icon-play-btn"></i>
            </strong>
          */}
        </div>
        {
         /* Video_URL &&
          <PlayVideo
            isOpen={isPlay}
            isCloseFunction={setPlay}
            videoId={Video_URL}
            isAutoPlay={1}
          />
        */ }
      </div>
    </React.Fragment>
  )
}

export default ProjectFactsSlider
